import "./../scss/main.scss";
import "./../scss/eligibleForm.scss";
import $ from "jquery";
import "bootstrap";

window.onload = (event) => {
    const nav = $("#navbar");
    const stickyCta = $("#stickyCta");
    const navHeight = nav.outerHeight();

    const typesOfDebtCtaTop = $("#typesOfDebtCta").position().top;
    const realWorldExamplesCtaTop = $("#realWorldExamplesCta").position().top;
    let triggeredTypesOfDebtModal = false;
    let triggeredRealWorldExamplesModal = false;

    let scrolling = false;
    const touchDevice = window.matchMedia("(pointer: coarse)").matches;

    // prevent modals from triggering if open page past the trigger point
    if ($(window).scrollTop > typesOfDebtCtaTop) {
        triggeredTypesOfDebtModal = true;
    } else if ($(window).scrollTop > realWorldExamplesCtaTop) {
        triggeredRealWorldExamplesModal = true;
    }

    // show success modal if coming from wizard page
    if (getUrlParameter("submit") === "true") {
        // show alert
        setTimeout(function () {
            document.querySelector("#submitSuccessAlert").classList.add("show");
        }, 600);
    }

    if (touchDevice) {
        // trigger the 'mobile modal' model 2s after visit page if on mobile device
        setTimeout(() => {
            $("#mobileModal").modal("show");
        }, 2000);
    } else {
        // trigger the 'before you go' model when mouse leave screen if on non-mobile device
        document.addEventListener("mouseout", function (evt) {
            if (evt.toElement == null && evt.relatedTarget == null) {
                $("#beforeGoModal").modal("show");
            }
        });
    }

    // trigger events on scrolling but rate limit to 300ms for optimisation
    $(window).on("scroll", function () {
        scrolling = true;
    });

    setInterval(() => {
        if (scrolling) {
            scrolling = false;
            let scroll = $(window).scrollTop();

            // show fixed cta button on scroll down
            let navBox = nav.position();
            let navBottom = navBox.top + navHeight;

            if (scroll >= navBottom) {
                stickyCta.fadeIn(600);
            } else {
                stickyCta.fadeOut(100);
            }

            // show mobile modal if hit trigger points (only show once)
            if (
                touchDevice &&
                scroll > realWorldExamplesCtaTop &&
                !triggeredRealWorldExamplesModal
            ) {
                $("#mobileModal").modal("show");
                triggeredRealWorldExamplesModal = true;
            }

            if (
                touchDevice &&
                scroll > typesOfDebtCtaTop &&
                !triggeredTypesOfDebtModal
            ) {
                $("#mobileModal").modal("show");
                triggeredTypesOfDebtModal = true;
            }
        }
    }, 300);
};

function getUrlParameter(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(location.search);
    return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
}
